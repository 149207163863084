import iso from 'iso-3166-1'
import { STORAGE } from '@/api/storage'
import { defaultLocale, localeOptions } from '@/constants/config'

export const COUNTRIES = _.sortBy(iso.all(), (a, b) => {
    if ( a.alpha3 == 'RUS') return -1;
})

export const isoMethods = {
    getName: function (v) {
        let c = iso.whereAlpha3(v);
        let imogi = v == 'RUS' ? '🇷🇺 ' : '';
        let name = locale == 'ru' ? new Intl.DisplayNames("ru", {type: "region"}).of(c.alpha2) : c?.country;
        return imogi + (name == 'Россия' ? 'Российская Федерация' : name);
    }
}

let locale = (STORAGE.getLang() && localeOptions.filter(x => x.id === STORAGE.getLang()).length > 0) ? STORAGE.getLang() : defaultLocale