<template lang="pug">
	b-modal(:id="`withdraw-created`" centered content-class="modal-withdraw-created mx-auto" body-class="p-0" hide-footer hide-header)
		.d-flex.flex-column
			img.mb-2.mx-auto.finance__withdraw-img(src="/assets/img/withdraw-created.png" alt="withdraw created")
			h3.text-center.mb-4 {{ $t('finance.withdraw.withdrawCreated') }}
			.btn.btn-primary.w-100.mt-auto(@click="$bvModal.hide(`withdraw-created`)") {{$t('wizard.success')}}
</template>


<script>
	import {mapGetters} from 'vuex'
	export default {
		name 		: 'ModalWithdrawalSuccess',
		
		components: {
		
		},
		
		data : () => ({
		
		}),
		
		mounted() {
		},

		created () {
			
		},

		computed: {
			
		},

		methods: {
			
		},

		watch : {
			
		}
	}
</script>
<style scoped lang='scss'>
	
</style>