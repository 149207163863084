<template lang='pug'>
	.d-flex.flex-column.justify-content-between.card-basic.bank-account(v-if="item" :class="item.isActive() ? 'active' : 'not-active'")
		span.b4.bank-account__text(:class="item.isActive() ? 'text-success' : 'text-grey'") {{ item.isActive() ? $t('project.activeCard') : $t('project.notActive') }}

		.d-flex.flex-column.flex-md-row.justify-content-between(v-if='item && item != null')
			// LEFT
			.d-flex.mb-1(v-if='isMobile')
				span.b1.text-bold.text-nowrap.mr-1(v-if='item.total' v-html='item.total.priceWithCurrency()')
				span.b3.text-grey.text-nowrap.mt-auto {{ $t('finance.available') }}&nbsp;
					span(v-if='item.available' v-html='item.available.priceWithCurrency()')
					span(v-else) 0&nbsp;
						span(v-if='item.total' v-html='item.total.currencyTitleRUB()')
			.d-flex.flex-column
				span.b3.mb-1(v-if="accountName") {{ accountName }}
				span.b3.text-grey.mb-1 {{ `#${item.id}` }}
				.d-flex(v-if='!(item.isIP() || item.isOOO() || item.isStripe())')
					.d-flex.align-items-center(v-if='item.hasCard()')
						.d-flex.mr-3.bank-account__img-card(v-if="item.card.systemType")
							img(:src='`/assets/img/icons/${item.card.systemType}.svg`')
						span.b3.text-grey.mr-3(v-if='item.card.pan') {{ item.card.pan | cardNumberLong }}
						i.fontello-btn.fontello-btn-small.fontello-btn-red.fontello-icon-trash(@click='openModalRemove()')
				.d-flex.flex-column(v-else-if='item.shop')
					span.b3.text-grey.mb-1 Р/с {{ item.shop.bankAccountNumber }}
					span.b3.text-grey(v-if='item.shop.bankAccountName') {{ item.shop.bankAccountName }}
				span.b3.text-grey(v-else-if='item.usdtAddress') {{ item.usdtAddress }}
				span.b3.text-primary.cursor-pointer.mt-auto.bank-account__add-link(v-if='item.isActive() && item.isTinkoff() && !item.hasCard()' @click='openModalAddCard()') {{ $t('finance.addCard2') }}

			// RIGHT
			.d-flex.flex-column.align-items-end
				span.b1.text-bold.text-nowrap.mb-1(v-if='item.total && !isMobile' v-html='item.total.priceWithCurrency()')
				span.b3.text-grey.text-nowrap.mb-1(v-if='!isMobile') {{ $t('finance.available') }}&nbsp;
					span(v-if='item.available' v-html='item.available.priceWithCurrency()')
					span(v-else) 0&nbsp;
						span(v-if='item.total' v-html='item.total.currencyTitleRUB()')
				.btn.btn-border.btn-small.bank-account__btn-withdrawal(v-if='hasInactiveShop' @click='openModalInactiveShop()') {{ $t('finance.doWithdrawal') }}
				.btn.btn-border.btn-small.bank-account__btn-withdrawal(v-else-if='hasWithdrawalButton' @click='openModalWithdrawal()') {{ $t('finance.doWithdrawal') }}
				.btn.btn-border.btn-small.bank-account__btn-withdrawal(v-else-if='(item.isActive() && item.isStripe() && item.usdtAddress != null)' @click="$bvModal.show(`withdraw-${item.id}`)") {{ $t('finance.doWithdrawal') }}
				.btn.btn-text.b3.mt-2.mr-auto.mr-md-0(v-else-if='item.isActive() && item.isTinkoff()' @click="openWithdrawSchedule()") {{ $t('finance.withdrawalSchedule') }}

		span.b3.text-primary.cursor-pointer.bank-account__add-link(v-if='item.isTinkoff() && !item.hasCard() && !item.isActive()' @click='openModalAddCard()') {{ $t('finance.addCard2') }}
		span.b3.text-primary.cursor-pointer.bank-account__add-link(v-else-if='!item.isTinkoff() && item.isActive() && item.isStripe() && item.usdtAddress == null' @click="openModalAddUSDTRequisites") {{ $t('finance.addRequisite') }}
		span.b3.text-primary.cursor-pointer.bank-account__add-link(v-else-if='!item.isTinkoff() && !item.isStripe() && !item.hasRequisite() && !item.hasCard()' @click='openModalEdit') {{ $t('finance.addRequisite') }}

		.d-flex.flex-column.bank-account__footer
			span.b4.text-grey(v-if='item.isStripe()') {{ $t('finance.accountOutside') }}
			span.b4.text-grey(v-else) {{ $t('finance.masterAccount') }}


		modal-general(:id="`add-requisite-${item.id}`" :contentClass="'max-content'" :title="$t('finance.addRequisite')")
			add-requisite(@openWithdrawCrypto="$bvModal.hide(`add-requisite-${item.id}`)")

		b-modal(:id="`withdraw-${item.id}`" centered content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer)
			template(#modal-header="{ close }")
				span.b1 {{ $t('finance.withdrawRequest') }}
				icon-close.bank-account__close(@click="close()")
			withdraw-crypto(@openWithdrawCreated="$bvModal.hide(`withdraw-${item.id}`), $emit('openWithdrawCreated')" @close='$bvModal.hide(`withdraw-${item.id}`)')
</template>

<script>
import { mapActions } from 'vuex';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import AddRequisite from '@/components/Modals/AddRequisite';
import WithdrawCrypto from '@/components/Modals/WithdrawCrypto';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'BankAccount',
	components: {
		ModalGeneral,
		AddRequisite,
		WithdrawCrypto,
		IconClose
	},
	props: {
		item: {
			default : null,
			type: Object
		},
	},
	data: () => ({
		errors: null,
	}),
	computed: {
		accountName() {
			if ( !this.item ) return null;
			if ( this.item.isStripe() ) return this.me.fio;

			if ( !this.item.shop ) return null;

			let text = this.item.shop.shortName;

			if ( text.length > 25 )
				return text.slice(0, 25) + '...'

			return text;
		},

		hasWithdrawalButton () {
			return (!this.item.isTinkoff() && this.item.shop != null) || this.item.usdtAddress;
		},
		hasInactiveShop() {
			if ( !this.item ) return false;

			return this.item.shop && this.item.shop.isInActive();
		}
	},
	methods : {
		...mapActions({
			checkForRemove: 'finance/removeCard',
		}),
		openModalWithdrawal() {
			if ( this.item.isStripe() && this.item.usdtAddress != null ) return this.openModalWithdrawalStripe();

			this.$store.commit('modals/setWithdrawal', null);
			this.$nextTick( () => {
				this.$store.commit('modals/setWithdrawal', this.item);
			});
		},
		openModalWithdrawalStripe() {
			this.$store.commit('modals/setWithdrawalStripe', null);
			this.$nextTick( () => {
				this.$store.commit('modals/setWithdrawalStripe', this.item);
				this.$bvModal.show(`withdraw-${this.item.id}`);
			});
		},
		openModalInactiveShop () {
			this.$store.commit('modals/setInactiveShop', null);
			this.$nextTick( () => {
				this.$store.commit('modals/setInactiveShop', this.item);
			});
		},

		openModalEdit () {
			if ( !this.item.isTinkoff() && !this.item.isStripe() && !(this.item.shop || this.item.shop != null)) {
				return this.$router.push({name: 'requisites', params: {id: this.item.id}})
			}

			this.$store.commit('finance/setWizardEditAccountOpened', null);
			this.$nextTick( () => {
				this.$store.commit('finance/setWizardEditAccountOpened', this.item);
			})
		},
		openModalAddUSDTRequisites() {
			this.$store.commit('modals/setUSDTRequisites', null);
			this.$nextTick( () => {
				this.$store.commit('modals/setUSDTRequisites', this.item);
				this.$bvModal.show(`add-requisite-${this.item.id}`)
			});
		},
		openModalRemove () {
			if ( this.item.isTinkoff() ) {
				this.item.canRemove = true;
				this.$emit('openModalRemove', this.item)
				return;
			}

			this.checkForRemove({id: this.item.id, is_possible: true})
				.then((can) => {
					this.item.canRemove = can;
					this.errors = null;
					this.$emit('openModalRemove', this.item)
				})
				.catch(() => {
					this.item.canRemove = false;
					this.$emit('openModalRemove', this.item);
				})
		},
		openModalAddCard() {
			this.$emit('openModalAddCard', this.item);
		},
		openWithdrawSchedule() {
			this.$store.commit('finance/setWithdrawSchedule', null);
			this.$nextTick( () => {
				this.$store.commit('finance/setWithdrawSchedule', this.item);
			})
		},

		needShowMoreAttachedProjects () {
			let container = document.getElementById(`ap${this.item.id}`);

			if (container ) {
				if ( container.scrollHeight > container.clientHeight) return this.isNeedShowMoreAttachedProjects = true;
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.bank-account {
	width: calc(50% - 10px);
	min-height: 201px;
	margin-bottom: 20px;
	overflow: hidden;

	@include respond-below(lg) {
		width: 100%;
		min-height: auto;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	&.active {
		position: relative;
		padding: 39px 20px 15px;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 24px;
			background-color: var(--brand-bg-success);
		}
	}

	&.not-active {
		position: relative;
		padding: 39px 20px 15px;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 24px;
			background-color: var(--border-line);
		}
	}

	&__text {
		position: absolute;
		top: 3px;
		left: 50%;
		transform: translateX(-50%);
	}

	&__btn-withdrawal {
		min-width: 120px;
		margin-top: 8px;

		@include respond-below(sm) {
			min-width: 100%;
			margin-top: 10px;
		}
	}

	&__img-card {
		padding: 8px;
		width: 66px;
		height: 38px;
		border-radius: 6px;
		border: 1px solid var(--border-line);
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__add-link {
		position: relative;
		padding-left: 28px;
		transition: var(--animation-time-short);

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 18px;
			height: 18px;
			mask-image: url('/assets/img/icons/add-circle.svg');
			background-color: var(--brand-primary);
			transform: translateY(-50%);
			transition: var(--animation-time-short);
		}

		&:hover,
		&:active {
			color: var(--primary-dark-text-color) !important;

			&::before {
				background-color: var(--primary-dark-text-color);
			}
		}

		@include respond-below(sm) {
			margin-top: 8px;
		}
	}


	&__footer {
		position: relative;
		padding-top: 31px;

		&::before {
			content: '';
			position: absolute;
			top: 15px;
			left: 0;
			right: 0;
			height: 1px;
			background-color: var(--border-line);
		}

		@include respond-below(sm) {
			padding-top: 21px;

			&::before {
				top: 10px;
			}
		}
	}

	&__withdraw-img {
		display: block;
		width: 192px;
		height: 192px;
	}

	&__close {
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}
}
</style>
