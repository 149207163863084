<template lang='pug'>
	.d-flex.cursor-pointer.position-relative.project-view(@click='onClick')
		svg.project-view__selected(v-if="isSelected" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg")
			path(d="M2.29883 9.24023L5.68129 12.9609L14.7012 3.03906" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
		.avatar-rect.avatar-rect-sm.mr-2
			img.avatar-rect-img(v-if='item && !item.isNeuro() && item.image' :src='item.image.contentUrl' :alt='item.title')
			span.avatar-rect-span(v-else-if="item && item.isNeuro() && !item.botImage && item.title ") {{ `${item.title.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1)}` }}
			img.avatar-rect-img(v-else-if="item && item.isNeuro()" :src="item.botImage ? item.botImage.contentUrl : '/assets/img/neurobots/neurobot.webp'" alt='Neurobot')

		.d-flex.flex-column
			span.b4.overflow-wrap.project-view__title {{ item.title ? item.title : $t('instruction.step0.neurobot.newNeurobot') }}
			b-badge.mt-1.mr-auto(v-if='badges && badges.length > 0' v-for="(badge, i) in badges" :key="`badge-${i}`" :variant='badges[i].variant' pill) {{ badges[i].text }}
</template>


<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ProjectView',
	props : {
		item: {
			type: Object,
			default: () => (null)
		}
	},
	methods: {
		onClick() {
			this.$emit('onClick', this.item.id);
		}
	},
	computed: {
		...mapGetters({
			lastOpenedProjectId: 'project/lastOpenedProjectId',
			openedProject: 'project/opened',
		}),
		badges () {
			let list = [];
			if ( this.item.hasSomeResourcesBotIssueNoTags())
				list.push({variant: 'warning', text: this.$t('bot.resourceAccessIssue4')})
			if ( this.item.isInActive() || !this.item.canSeeCover() && !this.item.isNeuro() || !this.item.somePlansHasResourceActive() && !this.item.isNeuro())
				list.push({variant: 'light', text: this.$t('project.notActive')})
			if ( this.item && this.item.isArchived() )
				list.push({variant: 'primary', text: this.$t('project.archived')})
			/* else if ( this.me && this.item && this.item.hasMoreThanOnePlanWithoutResource() )
				list.push({variant: 'warning', text: this.$t('project.finishSettings')}) */
			if ( this.me && this.item && this.item.isBlocked() )
				list.push({variant: 'danger', text: this.$t('forms.blocked')})
			if ( this.item && this.item.isNeuro() && this.item.bot && this.item.bot.isError())
				list.push({variant: 'danger', text: this.$t('resources.status.noContact')})

			return list;
		},
		isSelected () {
			return this.item && this.item.id && this.lastOpenedProjectId && this.openedProject && this.openedProject.id == this.item.id;
		}
	}
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.project-view {
	width: 100%;
	padding: 12px 15px;
	transition: var(--animation-time-short);

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 15px;
		right: 0;
		height: 1px;
		background-color: var(--border-line);
	}

	&:last-child {
		&::before {
			content: none;
		}
	}

	&:hover,
	&:active {
		background-color: var(--grayscale-bg-dark-back);
	}

	&__title {
		width: 127px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__selected {
		position: absolute;
		top: 9px;
		right: 13px;
		width: 18px;
	}
}
</style>
